/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/96c34237c15378bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c7459cb971eeb180-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7d37c3d1d00517d7-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/79df5d0d965f5f01-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c79fa49c85787413-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d6cb62f33db53984-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ecf09e81d7db431c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/96c34237c15378bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c7459cb971eeb180-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7d37c3d1d00517d7-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/79df5d0d965f5f01-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c79fa49c85787413-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d6cb62f33db53984-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ecf09e81d7db431c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/96c34237c15378bf-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c7459cb971eeb180-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7d37c3d1d00517d7-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/79df5d0d965f5f01-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c79fa49c85787413-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d6cb62f33db53984-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ecf09e81d7db431c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1e1d9f7ee33f5a4d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e1dc9e24678282b7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ce5675ba4481ec73-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ac590a4a811bfa12-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cbac608cb004e0e5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0ad5d54e4e8ec124-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e7c7dbb62ddcf6fa-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/1e1d9f7ee33f5a4d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e1dc9e24678282b7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ce5675ba4481ec73-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ac590a4a811bfa12-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/cbac608cb004e0e5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0ad5d54e4e8ec124-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e7c7dbb62ddcf6fa-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1e1d9f7ee33f5a4d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e1dc9e24678282b7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ce5675ba4481ec73-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ac590a4a811bfa12-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cbac608cb004e0e5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0ad5d54e4e8ec124-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_396320';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e7c7dbb62ddcf6fa-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_396320';src: local("Arial");ascent-override: 108.65%;descent-override: 42.44%;line-gap-override: 0.00%;size-adjust: 94.24%
}.__className_396320 {font-family: '__Source_Sans_3_396320', '__Source_Sans_3_Fallback_396320'
}

